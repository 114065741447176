@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
// @include foundation-grid;
// @include foundation-flex-grid;
//
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
//@include foundation-accordion;
//@include foundation-accordion-menu;
//@include foundation-badge;
//@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
//@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
//@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;

@include motion-ui-transitions;
@include motion-ui-animations;


.logo {
	width: 2rem;
	height: auto;
}


#mainNavigation{
	.logo{
		margin: 0 0.5rem 0 0.75rem;
	}
}

.top-bar-left{
	a, span{
		color: $black;
	}
}

.trennlinie{
	sposition: relative;
	display: none;
	z-index: 99999;
    max-width: 100%;
    width: 100%;
    float: left;
    background-color: $primary-color;
 
    height: 0.5rem;
    
	&:after {
		left: 50%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(136, 183, 213, 0);
		border-top-color: $primary-color;
		border-width: 8px;
		margin-top: 8px;
		margin-left: -8px;
	}
}

.orbit{
	z-index: 1;
	width: 100%;
	
	img{ width: 100%; }
			
	.slide,
	.orbit-slide{
		outline: none !important;
	}
		
	.slide{
		height: 40em;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}
}

.orbit-container{
	max-height: 480px;
}


.intro{
   margin-top: 3rem;
   margin-bottom: 2rem;
 
}

.submenu{ z-index: 999;	}


section{
	margin: 2rem 0;
    float: left;
    min-height: 24rem;
    width: 100%;
}


.table-forms{
	@extend table;
	margin: 0 auto;
	width: 80%;
	display: inline-block;

	
	.head{
		@extend thead;
		background-color: $table-head-background;
	}
	
	.cell{
		padding: 0.5rem 1rem; 
		text-align: left;
	}
	
	>.cell{
		@extend tr;
	
		&.odd{
			background-color: $table-head-background;
		}
		
		&.even{
			background-color: $table-striped-background;
		}
	}
}


.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after{
	left: -7px;
}


/***********************************************************
* MEDIA QUERIES
************************************************************/

@media screen and #{breakpoint(medium)} {
	 .trennlinie{
	  	display: inline-block;
	 }

	.dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
	    top: 120%;
	}
}


@media screen and #{breakpoint(medium down)} {
	
	.slide{
		height: 20em;
	}
	
	
	.intro{
	   margin-top: 1rem;  
	}  
	
  .table-forms{
	width: 100%;
  }
}


